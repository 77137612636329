<ng-container *transloco="let t">
  <form [formGroup]="formGroup">
    <p-selectButton
      cssSelector=".p-button.p-component[role='radio']"
      formControlName="value"
      optionDisabled="disabled"
      optionValue="value"
      sharedAriaLabelModifier
      [options]="toggleOptions()"
      (onChange)="selectedItemChanged.emit($event.value)">
      <ng-template let-option pTemplate="item">
        <i class="p-button-icon-left pi pi-{{ option.icon }}"></i>
        <span class="p-button-label">{{ t(option.i18nKey) }}</span>
      </ng-template>
    </p-selectButton>
  </form>
</ng-container>
