import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { SelectButtonModule } from 'primeng/selectbutton';

import { AriaLabelModifierDirective } from '../../../directives';
import { TranslatableSelectItem } from '../../../models';

@Component({
  selector: 'shared-toggle-btn',
  standalone: true,
  imports: [
    CommonModule,
    SelectButtonModule,
    ReactiveFormsModule,
    AriaLabelModifierDirective,
    TranslocoDirective,
  ],
  templateUrl: './toggle-btn.component.html',
  styleUrl: './toggle-btn.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleBtnComponent implements OnInit {
  private _items: TranslatableSelectItem[] = [];

  formGroup!: FormGroup;
  toggleOptions = signal<TranslatableSelectItem[]>([]);

  @Input() set items(value: TranslatableSelectItem[]) {
    if (!value) {
      return;
    }

    this._items = value;
    this.toggleOptions.set(this._items);
  }

  @Input() selectedItem = '';

  @Output() selectedItemChanged = new EventEmitter<string>();

  ngOnInit() {
    this.formGroup = new FormGroup({
      value: new FormControl(this.selectedItem),
    });

    this.selectedItemChanged.emit(this.selectedItem);
  }
}
