<ng-container *transloco="let t">
    @if (useCustomLegend) {
      <div [id]="legendId"></div>
    }
    <canvas #chartCanvas [style.display]="hasData ? 'block' : 'none'"></canvas>
    @if(!hasData) {
      <div class="no-data">
        <p>{{ t('noDataAvailable') }}</p>
      </div>
    }
</ng-container>
