<ng-container *transloco="let t">
  <div id="content-table">
    <p-table
      #dt
      columnResizeMode="expand"
      role="table"
      scrollHeight="flex"
      styleClass="p-datatable-striped"
      [attr.aria-label]="t('dataTable')"
      [autoLayout]="true"
      [columns]="visibleCols"
      [currentPageReportTemplate]="t('paginationMessage')"
      [customSort]="true"
      [dataKey]="dataKey"
      [filterDelay]="filterDelay"
      [lazy]="true"
      [lazyLoadOnInit]="true"
      [loading]="loading"
      [ngClass]="{
        'pagination-partially-visible': isPaginationPartiallyVisible,
      }"
      [paginator]="isPaginationVisible"
      [resizableColumns]="true"
      [rowHover]="rowHover"
      [rows]="rows"
      [scrollable]="scrollable"
      [showCurrentPageReport]="showCurrentPageReport"
      [sortMode]="sortMode"
      [styleClass]="rowSize"
      [totalRecords]="totalRecords"
      [value]="data"
      (onLazyLoad)="sortable && onLazyLoadChanged($event)"
      (sortFunction)="customSort($event)">

      <ng-template pTemplate="caption">
        <div
          class="container-actions"
          [ngClass]="{ 'has-row-gap': displaySaveFilterButton || displayClearButton }">
          <div class="action-buttons">
            @if (displayExportButton && localDisplayExportButton) {
              <button
                class="p-button-outlined grouping-button"
                icon="pi pi-download"
                pButton
                sharedDebounceClick
                [attr.aria-label]="t('exportToExcel')"
                [label]="t('export') | titlecase"
                (debounceClick)="exportExcel.emit()">
              </button>
            }

            @if (displayColsReorderButton && localDisplayColsReorderButton) {
              <button
                #trigger="cdkOverlayOrigin"
                cdkOverlayOrigin
                class="p-button-outlined grouping-button"
                icon="pi pi-table"
                pButton
                [attr.aria-label]="t('modifyColumnsSettings')"
                [label]="t('columns') | titlecase"
                (click)="isColsOverlayOpen = !isColsOverlayOpen">
              </button>
  
              <ng-template
                cdkConnectedOverlay
                [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
                [cdkConnectedOverlayHasBackdrop]="true"
                [cdkConnectedOverlayOffsetY]="6"
                [cdkConnectedOverlayOpen]="isColsOverlayOpen"
                [cdkConnectedOverlayOrigin]="trigger"
                (backdropClick)="isColsOverlayOpen = !isColsOverlayOpen">
                <shared-grid-columns
                  [columns]="userSortedCols"
                  [showDefaultButton]="showDefaultColumnsButton"
                  (applied)="onGridColumnsApplyClicked($event)"
                  (defaulted)="onGridColumnDefaultClicked()">
                </shared-grid-columns>
              </ng-template>
            }

            <ng-container *ngTemplateOutlet="buttonsCustomTemplate"></ng-container>
          </div>

          @if (selectable && showSelectedCount) {
            <div class="counter">
              <button
                class="p-button-outlined grouping-button"
                icon="pi pi-times table-header__icon-times"
                pButton
                [attr.aria-label]="t('selected')"
                [label]="selectedCount + ' ' + t('selected')"
                (click)="onClearSelection()">
              </button>
            </div>
          }

          @if (displayFilterSummary) {
            <div
              class="filter-list"
              role="list"
              [attr.aria-label]="t('activeFilters')">
              @if (filteringConfig$ | async; as filteringConfig) {
                <shared-active-filter-tags
                  [columns]="columns"
                  [filteringConfig]="filteringConfig"
                  [savedFilters]="preferenceToSave.filters"
                  (deleteSavedFilter)="onDeleteIndividualFilter($event)"
                  (removeFilter)="onRemoveFilterTags($event)"
                  (saveFilter)="onSaveIndividualFilter($event)">
                </shared-active-filter-tags>
              }
            </div>
          }

          @if (displaySaveFilterButton || displayClearButton) {
            <div class="filter-buttons">
              @if (displaySaveFilterButton) {
                <button
                  class="p-button-outlined grouping-button"
                  icon="pi pi-bookmark"
                  pButton
                  [attr.aria-label]="t('lockFilters')"
                  [label]="t('saveFilters')"
                  (click)="onSaveFiltersClick()">
                </button>
              }
              @if (displayClearButton) {
                <button
                  class="p-button-outlined grouping-button"
                  icon="pi pi-filter-slash"
                  pButton
                  [attr.aria-label]="t('clearFilters')"
                  [label]="t('clearFilters')"
                  (click)="clearFilters()">
                </button>
              }
            </div>
          }
        </div>
      </ng-template>

      <ng-template let-columns pTemplate="header">
        <tr class="header-row">
          @if (selectable) {
            <th
              class="select-tristate"
              pFrozenColumn
              style="width: 4rem"
              [frozen]="selectableSticky">
              <p-triStateCheckbox
                [ariaLabel]="t('selectRowsColumn')"
                [checkboxFalseIcon]="'pi pi-minus'"
                [(ngModel)]="selectAll"
                (onChange)="onChangeSelectAll($event)"></p-triStateCheckbox>
              <span class="sr-only">t('selectRowsColumn')</span>
            </th>
          }
          @for (col of columns; track col.field) {
            @switch (col.type) {
              @case (ColumnType.TextSearchFilter) {
                <th
                  class="table-header-dimension"
                  pFrozenColumn
                  pResizableColumn
                  scope="col"
                  [frozen]="col.sticky"
                  [pSortableColumn]="col.field"
                  [style.width]="col.width || 'inherit'">
                  <div class="table-header__th">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    <span>{{ t(col.displayName) | uppercase }}</span>
                    <p-columnFilter
                      display="menu"
                      matchMode="startsWith"
                      [field]="col.field"
                      [showAddButton]="false"
                      [showApplyButton]="false"
                      [showClearButton]="false"
                      [showMatchModes]="false"
                      [showOperator]="false">
                      <ng-template
                        let-filter="filterCallback"
                        let-value
                        pTemplate="filter">
                        <shared-input-filter
                          [field]="col.field"
                          [filter]="filter"
                          [filteringConfig$]="filteringConfig$"></shared-input-filter>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </th>
              }
              @case (ColumnType.SearchCheckboxFilter) {
                <th
                  class="table-header-dimension"
                  pFrozenColumn
                  pResizableColumn
                  scope="col"
                  [frozen]="col.sticky"
                  [pSortableColumn]="col.field"
                  [style.width]="col.width || 'inherit'">
                  <div class="table-header__th">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    <span>{{ t(col.displayName) | uppercase }}</span>
                    <p-columnFilter
                      display="menu"
                      matchMode="in"
                      [field]="col.field"
                      [showAddButton]="false"
                      [showApplyButton]="false"
                      [showClearButton]="false"
                      [showMatchModes]="false"
                      [showOperator]="false">
                      <ng-template
                        let-filter="filterCallback"
                        let-value
                        pTemplate="filter">
                        <shared-checkbox-filter
                          [field]="col.field"
                          [filter]="filter"
                          [filterFlag]="true"
                          [filteringConfig$]="filteringConfig$"
                          [options]="filterOptions[col.field]">
                        </shared-checkbox-filter>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </th>
              }
              @case (ColumnType.CheckboxFilter) {
                <th
                  class="table-header-dimension"
                  pFrozenColumn
                  pResizableColumn
                  scope="col"
                  [frozen]="col.sticky"
                  [pSortableColumn]="col.field"
                  [style.width]="col.width || 'inherit'">
                  <div class="table-header__th">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    <span>{{ t(col.displayName) | uppercase }}</span>
                    <p-columnFilter
                      display="menu"
                      matchMode="in"
                      [field]="col.field"
                      [showAddButton]="false"
                      [showApplyButton]="false"
                      [showClearButton]="false"
                      [showMatchModes]="false"
                      [showOperator]="false">
                      <ng-template
                        let-filter="filterCallback"
                        let-value
                        pTemplate="filter">
                        <shared-checkbox-filter
                          [field]="col.field"
                          [filter]="filter"
                          [filterFlag]="false"
                          [filteringConfig$]="filteringConfig$"
                          [options]="filterOptions[col.field]">
                        </shared-checkbox-filter>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </th>
              }
              @case (ColumnType.DateFilter) {
                <th
                  class="table-header-dimension"
                  pFrozenColumn
                  pResizableColumn
                  scope="col"
                  [frozen]="col.sticky"
                  [pSortableColumn]="col.field"
                  [style.width]="col.width || 'inherit'">
                  <div class="table-header__th">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                    <span>{{ t(col.displayName) | uppercase }}</span>
                    <p-columnFilter
                      display="menu"
                      type="date"
                      [field]="col.field"
                      [matchMode]="getMatchMode(col.field)"
                      [showAddButton]="false"
                      [showApplyButton]="false"
                      [showClearButton]="false"
                      [showMatchModes]="false"
                      [showOperator]="false">
                      <ng-template
                        let-filter="filterCallback"
                        let-value
                        pTemplate="filter">
                        <shared-date-filter
                          [field]="col.field"
                          [filter]="filter"
                          [filteringConfig$]="filteringConfig$"
                          [selectionMode]="'range'">
                        </shared-date-filter>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                </th>
              }
              @case (ColumnType.Actions) {
                <th
                  alignFrozen="right"
                  class="table-header-dimension-actions"
                  pFrozenColumn
                  scope="col"
                  [frozen]="col.sticky">
                  <span class="sr-only">t('actionsColumn')</span>
                </th>
              }
              @case (ColumnType.EventActions) {
                <th
                  alignFrozen="right"
                  class="table-header-dimension-event-actions"
                  pFrozenColumn
                  scope="col"
                  [frozen]="col.sticky"
                  [ngClass]="col.customClass"
                  [pSortableColumn]="col.field">
                  <div class="table-header__th">
                    <span class="sr-only">t('eventActionsColumn')</span>
                  </div>
                </th>
              }
            }
          }
        </tr>
      </ng-template>
      <ng-template
        let-columns="columns"
        let-rowData
        let-rowIndex="rowIndex"
        pTemplate="body">
        <tr
          class="p-selectable-row"
          [ngClass]="{ 'selected-row': rowData.isSelected }">
          @if (selectable) {
            <td
              class="p-selectable-row"
              pFrozenColumn
              [frozen]="selectableSticky"
              [ngClass]="{ 'p-selectable-row--has-child': rowData.hasChild }">
              <p-checkbox
                ariaLabel="selectRowCheckbox"
                binary="true"
                class="parent-checkbox"
                [(ngModel)]="rowData.isSelected"
                (onChange)="updateSelectedRows()">
              </p-checkbox>

              @if (rowData.hasChild) {
                @for (
                  child of rowData.children;
                  track child[childIdentifier];
                  let childIdx = $index
                ) {
                  <div>
                    <p-checkbox
                      ariaLabel="selectRowCheckbox"
                      binary="true"
                      class="child-checkbox"
                      [(ngModel)]="rowData.children[childIdx].isSelected"
                      (onChange)="updateSelectedRows()">
                    </p-checkbox>
                  </div>
                }
              }
            </td>
          }
          @for (col of columns; track col.field) {
            @switch (col.cellType) {
              @case (CellType.Checkbox) {
                <td pFrozenColumn [frozen]="col.sticky">
                  <p-tableCheckbox
                    [value]="rowData[col.field]"></p-tableCheckbox>
                </td>
              }
              @case (CellType.Link) {
                <td
                  pFrozenColumn
                  [frozen]="col.sticky"
                  [ngClass]="{ 'row-child': rowData.hasChild }"
                  [sharedTippyTooltip]="rowData[col.field]"
                  [style.max-width.%]="100"
                  [tooltipTheme]="TooltipTheme.LightMd">
                  <span class="p-column-title">{{ col.displayName }}</span>
                  @if (route) {
                    <a
                      ariaCurrentWhenActive="page"
                      attr.aria-label="Navigate to id {{ rowData[col.field] }}"
                      [queryParams]="
                        createQueryParams(queryParamProperty, rowData)
                      "
                      [routerLink]="[route, rowData[col.field]]"
                      >{{ rowData[col.field] }}</a
                    >
                  } @else {
                    <div
                      [ngClass]="{
                        'parent-cell': rowData[col.field],
                        'empty-cell': !rowData[col.field],
                      }"
                      [sharedTippyTooltip]="rowData[col.field]"
                      [tooltipTheme]="TooltipTheme.LightMd">
                      <a attr.aria-label="Id {{ rowData[col.field] }}">{{
                        rowData[col.field]
                      }}</a>
                    </div>
                    @if (rowData.hasChild) {
                      @for (
                        child of rowData.children;
                        track child[childIdentifier]
                      ) {
                        <div
                          class="child-cell dummy-cell"
                          [sharedTippyTooltip]="child[col.field]"
                          [tooltipTheme]="TooltipTheme.LightMd">
                          <a attr.aria-label="Id {{ child[col.field] }}">{{
                            child[col.field]
                          }}</a>
                        </div>
                      }
                    }
                  }
                </td>
              }
              @case (CellType.Tag) {
                <td
                  class="tag"
                  pFrozenColumn
                  [frozen]="col.sticky"
                  [style.max-width]="gridSizeConfig.minStatusTdDefaultWidth">
                  <span class="p-column-title">{{ col.displayName }}</span>
                  <shared-tag
                    [tagClassesMap]="tagStatesMap"
                    [tagValue]="rowData[col.field]"></shared-tag>
                </td>
              }
              @case (CellType.Status) {
                <td
                  pFrozenColumn
                  [frozen]="col.sticky"
                  [ngClass]="{ 'row-child': rowData.hasChild }"
                  [style.max-width]="gridSizeConfig.minStatusTdDefaultWidth">
                  <span class="p-column-title">{{ col.displayName }}</span>
                  <div
                    [ngClass]="{
                      'parent-cell': rowData[col.field],
                      'empty-cell': !rowData[col.field],
                    }">
                    <shared-status
                      [statusClassMap]="statusStatesMap"
                      [value]="rowData[col.field]"></shared-status>
                  </div>
                  @if (rowData.hasChild) {
                    @for (
                      child of rowData.children;
                      track child[childIdentifier]
                    ) {
                      <div
                        class="child-cell dummy-cell"
                        [sharedTippyTooltip]="child[col.field]"
                        [tooltipTheme]="TooltipTheme.LightMd">
                        <span class="child-status-icon"></span>
                        <span class="child-status-text">{{
                          child[col.field]
                        }}</span>
                      </div>
                    }
                  }
                </td>
              }
              @case (CellType.Date) {
                <td
                  pFrozenColumn
                  [frozen]="col.sticky"
                  [ngClass]="{ 'row-child': rowData.hasChild }"
                  [style.max-width]="gridSizeConfig.minTdDateWidth">
                  <span class="p-column-title">{{ col.displayName }}</span>
                  <div
                    [ngClass]="{
                      'parent-cell': rowData[col.field],
                      'empty-cell': !rowData[col.field],
                    }"
                    [sharedTippyTooltip]="rowData[col.field]"
                    [tooltipTheme]="TooltipTheme.LightMd">
                    {{ rowData[col.field] }}
                  </div>
                  @if (rowData.hasChild) {
                    @for (
                      child of rowData.children;
                      track child[childIdentifier]
                    ) {
                      <div class="child-cell dummy-cell">
                        {{ child[col.field] }}
                      </div>
                    }
                  }
                </td>
              }
              @case (CellType.Actions) {
                <td
                  alignFrozen="right"
                  class="action-column"
                  pFrozenColumn
                  [frozen]="col.sticky"
                  [ngClass]="{ 'row-child': rowData.hasChild }"
                  [style.max-width]="gridSizeConfig.minTdActionsWidth">
                  <div
                    [ngClass]="{
                      'parent-cell': rowData[col.field],
                      'empty-cell': !rowData[col.field],
                    }">
                    <shared-action
                      [actions]="rowData.actions"
                      (triggerFileAction)="
                        onFileActionTrigger($event, rowData)
                      ">
                    </shared-action>
                  </div>
                  @if (rowData.hasChild) {
                    @for (
                      child of rowData.children;
                      track child[childIdentifier]
                    ) {
                      <div class="child-cell dummy-cell">
                        <shared-action
                          [actions]="child.actions"
                          (triggerFileAction)="
                            onFileActionTrigger($event, child)
                          ">
                        </shared-action>
                      </div>
                    }
                  }
                </td>
              }
              @case (CellType.EventActions) {
                <td
                  alignFrozen="right"
                  class="action-column"
                  pFrozenColumn
                  [frozen]="col.sticky"
                  [ngClass]="{
                    'row-child': rowData.hasChild,
                    sm:
                      rowData.eventActions?.actions?.length &&
                      rowData.actions?.length,
                  }"
                  [style.max-width]="gridSizeConfig.minTdActionsWidth">
                  <div
                    [ngClass]="{
                      'parent-cell': rowData[col.field],
                      'empty-cell': !rowData[col.field],
                    }">
                    <shared-event-action
                      [displayConfirmButton]="
                        rowData.eventActions.displayConfirmButton
                      "
                      [eventActions]="rowData.eventActions.actions"
                      [id]="rowData.eventActions.id"
                      (triggerEventAction)="onEventActionTrigger($event)">
                    </shared-event-action>
                  </div>
                  @if (rowData.hasChild) {
                    @for (
                      child of rowData.children;
                      track child[childIdentifier]
                    ) {
                      <div class="child-cell dummy-cell"></div>
                    }
                  }
                </td>
              }
              @case (CellType.TextWithIcon) {
                <td pFrozenColumn [frozen]="col.sticky">
                  <span class="p-column-title">{{ col.displayName }}</span>
                  <shared-text-with-icon
                    [displayIcon]="rowData.primarySite.isPrimarySite"
                    [iconClass]="rowData.primarySite.iconClass"
                    [text]="rowData[col.field]"
                    [tooltipMessage]="rowData.primarySite.tooltipMessage">
                  </shared-text-with-icon>
                </td>
              }
              @default {
                <td
                  pFrozenColumn
                  [frozen]="col.sticky"
                  [ngClass]="{ 'row-child': rowData.hasChild }"
                  [style.max-width]="gridSizeConfig.minTdDefaultWidth">
                  <span class="p-column-title">{{ col.displayName }}</span>
                  <div
                    [ngClass]="{
                      'parent-cell': rowData[col.field],
                      'empty-cell': !rowData[col.field],
                    }"
                    [sharedTippyTooltip]="rowData[col.field]"
                    [tooltipTheme]="TooltipTheme.LightMd">
                    {{ rowData[col.field] }}
                  </div>
                  @if (rowData.hasChild) {
                    @for (
                      child of rowData.children;
                      track child[childIdentifier]
                    ) {
                      <div
                        class="child-cell dummy-cell"
                        [ngClass]="col.customClass"
                        [sharedTippyTooltip]="child[col.field]"
                        [tooltipTheme]="TooltipTheme.LightMd">
                        {{ child[col.field] }}
                      </div>
                    }
                  }
                </td>
              }
            }
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr class="grid-message">
          <td [attr.colspan]="colsNo">{{ t(noDataMessage) }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorright">
        <span>{{ t('itemsPerPage') }}:</span>
        <p-dropdown
          appendTo="body"
          ariaLabel="Change rows count"
          [options]="rowsPerPageOptions"
          [(ngModel)]="rows"
          (ngModelChange)="onChangeRows($event)"
          (onChange)="onSelectPaginationRows()" />
      </ng-template>
    </p-table>
  </div>
</ng-container>
