const initializeTooltipContainer = (chart: any): HTMLDivElement => {
  let tooltipContainer = chart.canvas.parentNode.querySelector(
    'div.tooltip',
  ) as HTMLDivElement;

  if (!tooltipContainer) {
    tooltipContainer = document.createElement('div');
    tooltipContainer.classList.add('tooltip');

    // Tooltip base styles
    Object.assign(tooltipContainer.style, {
      background: 'rgba(255, 255, 255, 1)',
      borderRadius: '8px',
      color: 'white',
      opacity: 1,
      position: 'absolute',
      transform: 'translate(-50%, 0)',
      transition: 'all .4s ease',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      zIndex: '1000',
      pointerEvents: 'auto',
    });

    // Tooltip mouse events for opacity
    tooltipContainer.onmouseover = () => {
      tooltipContainer.style.opacity = '1';
    };

    tooltipContainer.onmouseout = () => {
      tooltipContainer.style.opacity = '0';
    };

    chart.canvas.parentNode.appendChild(tooltipContainer);
  }

  return tooltipContainer;
};

const createTooltipTitle = (titleText: string): HTMLDivElement => {
  const titleContainer = document.createElement('div');
  const titleElement = document.createElement('p');
  titleElement.textContent = titleText;

  Object.assign(titleElement.style, {
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#0F204B',
    marginTop: '10px',
    marginLeft: '10px',
    marginRight: '10px',
  });

  titleContainer.appendChild(titleElement);

  return titleContainer;
};

const createTooltipDataEntry = (
  label: string,
  value: string,
  color: string,
  percentage?: string,
): HTMLParagraphElement => {
  const entryContainer = document.createElement('p');
  const icon = document.createElement('span');
  const labelText = document.createElement('span');

  Object.assign(icon.style, {
    background: color,
    borderWidth: '2px',
    borderRadius: '50%',
    marginRight: '10px',
    height: '10px',
    width: '10px',
    display: 'inline-block',
  });

  Object.assign(labelText.style, {
    fontWeight: '400',
    fontSize: '16px',
    color: '#000',
    display: 'inline-block',
  });

  if (percentage) {
    labelText.innerHTML = label
      ? `${label}: <b>${value}</b> (${percentage}%)`
      : `<b>${value}</b> (${percentage}%)`;
  } else {
    labelText.textContent = label ? `${label}: ${value}` : value;
  }

  entryContainer.appendChild(icon);
  entryContainer.appendChild(labelText);
  Object.assign(entryContainer.style, {
    display: 'block',
    whiteSpace: 'nowrap',
    marginLeft: '10px',
    marginRight: '10px',
  });

  return entryContainer;
};

const createTooltipButton = (onclick: () => void): HTMLButtonElement => {
  const button = document.createElement('button');
  button.textContent = 'View Details \u279C';
  Object.assign(button.style, {
    backgroundColor: '#fff',
    border: '1px solid #003591',
    color: '#003591',
    borderRadius: '10px',
    padding: '8px 12px',
    marginTop: '4px',
    marginBottom: '10px',
    marginLeft: '10px',
    marginRight: '10px',
    fontSize: '14px',
    fontWeight: '700',
    whiteSpace: 'nowrap',
  });

  button.onclick = onclick;

  return button;
};

export const externalTooltipPlugin = (
  context: any,
  customTitle?: string,
  shouldShowPercentage?: boolean,
) => {
  const { chart, tooltip } = context;
  const tooltipContainer = initializeTooltipContainer(chart);

  // Hide tooltip if there's no data to show
  if (tooltip.opacity === 0) {
    tooltipContainer.style.opacity = '0';

    return;
  }

  // Clear existing content
  tooltipContainer.innerHTML = '';

  // Add custom title if provided
  const titleText = customTitle || (tooltip.title && tooltip.title.join(', '));

  if (titleText) {
    tooltipContainer.appendChild(createTooltipTitle(titleText));
  }

  // Populate tooltip with data entries
  if (tooltip.body) {
    tooltip.body.forEach((_bodyItem: string, index: number) => {
      const labelColor = tooltip.labelColors[index].backgroundColor;
      const bodyText = tooltip.body[index]?.lines?.join(', ') || '';
      const label = customTitle ? tooltip.title[index] : '';

      let dataEntry: HTMLParagraphElement;

      // TODO use percentage from API
      if (shouldShowPercentage) {
        const percentage = context.chart.config.data.percentageValues[label];

        dataEntry = createTooltipDataEntry(
          label,
          bodyText,
          labelColor,
          percentage,
        );
      } else {
        dataEntry = createTooltipDataEntry(label, bodyText, labelColor);
      }

      tooltipContainer.appendChild(dataEntry);
    });

    // Add "View Details" button
    const button = createTooltipButton(() =>
      console.log('chart', tooltip.body[0].lines, tooltip.title),
    );
    tooltipContainer.appendChild(button);
  }

  // Position and style tooltip container
  const { offsetLeft, offsetTop } = chart.canvas;
  const { caretX, caretY, options } = tooltip;
  const { bodyFont, padding } = options;
  Object.assign(tooltipContainer.style, {
    opacity: 1,
    left: `${offsetLeft + caretX}px`,
    top: `${offsetTop + caretY}px`,
    font: bodyFont.string,
    padding: `${padding}px ${padding}px`,
  });
};
