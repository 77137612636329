<ng-container *transloco="let t">
  <p-multiSelect
    [ariaLabel]="ariaLabel()"
    [autoOptionFocus]="false"
    [filter]="hasSearch()"
    [ngModel]="selected()"
    [options]="options()"
    [placeholder]="placeholder()"
    [scrollHeight]="scrollHeight"
    [showToggleAll]="false"
    (onChange)="onChange($event)">

    <ng-template pTemplate="header">
      <p-triStateCheckbox
        [checkboxFalseIcon]="'pi pi-minus'"
        [label]="t('all')"
        [ngModel]="triState()"
        (onChange)="onChangeTriState($event)">
      </p-triStateCheckbox>
    </ng-template>

  </p-multiSelect>
</ng-container>